import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
//import {Link} from 'react-scroll'
export const Nav = styled.nav `
    background: #131313;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }

`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 20px 24px;
    max-width: 1600px;

`

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 986px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    @media screen and (max-width: 986px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
`

export const NavLinks = styled(LinkR)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &:hover{
        color: #ff3026;
    }
    &.active{
        border-bottom: 3px solid #01bf71;
    }

`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    gap:5px;
    @media screen and (max-width: 986px){
        display: none
    }
`

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background-color: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        backgroun: #fff;
        color: #ff3026

    }
`
export const NavBtnLink2 = styled.a`
    border-radius: 30px;
    background: rgb(255,48,38);
    background: -moz-linear-gradient(135deg, rgba(255,48,38,1) 0%, rgba(138,19,13,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(255,48,38,1) 0%, rgba(138,19,13,1) 100%);
    background: linear-gradient(135deg, rgba(255,48,38,1) 0%, rgba(138,19,13,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3026",endColorstr="#8a130d",GradientType=1);
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606

    }
`
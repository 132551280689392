import React from 'react'
import { FaTelegram, FaTwitter } from 'react-icons/fa'

const Footer = () => {
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", background:"black", color:"white", height:"30px"}}>
      <div style={{maxWidth:"800px", display:"flex", justifyContent:"space-between", alignItems:"center", gap:"25px", background:"black", color:"white"}}>
        Terms and Conditions  
        <div style={{display:"flex", gap:"15px"}}>
        <FaTelegram/>
        <FaTwitter/>
        </div>
        
      </div>
    </div>
  )
}

export default Footer
import React, { useEffect, useRef, useState } from 'react'
import { ActionButtons, FeaturesContainer, FeaturesContainerMain, FeaturesWrapper, GlassInfoBox, HomeContainer, HomeMainContainer, HomeWrapper, LoginWrapper } from './HomeElements'
import Logo from "../../components/assets/images/logo.png"

import { FaAngleDown, FaChevronDown, FaChevronUp, FaDownload, FaGasPump, FaPen} from 'react-icons/fa'
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TextField from '@mui/material/TextField';

import styled from "styled-components";
import Slide from '@mui/material/Slide';
import axios from "axios"
import { CircularProgress } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const WhiteBorderTextField = styled(TextField)`
& .MuiFormLabel-root{
  color:white;
}
& .MuiOutlinedInput-notchedOutline{
  border-color:rgba(255,255,255,0.3);
}
& .MuiInputBase-input {
  color: white;
}
& label.Mui-focused {
  color: white;
}
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: white;
  }
}
`;
const StyledCell = styled(TableCell)`
  & .MuiTableCell-root{
    fontWeight:200;
    border-bottom:1px solid rgba(224, 224, 224, 0);
  }
  
`;
const HomePage = ({login, setLogin,jwtToken}) => {
  const telegramWrapperRef = useRef(null);
  useEffect(() =>{
    if(login){
      axios.get('https://auth.nukebot.io/verify-token',{
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })
      .then(res => {
        setLogin(true)
          console.log('Server response:', res.data);
          // Handle server response
      })
      .catch(error => {
        setLogin(false)
          console.error('Error posting data to the server:', error);
      });
    }
  },[login,jwtToken])
  useEffect(() => {
    if(!login){
        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
        scriptElement.setAttribute('data-telegram-login', 'nukebot_1_bot');
        scriptElement.setAttribute('data-size', 'large');
        scriptElement.setAttribute('data-auth-url', 'https://auth.nukebot.io/auth');
        scriptElement.async = true;
      
        telegramWrapperRef?.current?.appendChild(scriptElement);
    }
  }, [login]);
  const handleTelegramResponse = (response) => {
    console.log('Telegram data:', response);

    // Send data to backend for further processing (e.g., verification and storing in DB)
    axios.post('http://localhost:3001/auth/telegram', response)
        .then(res => {
            console.log('Server response:', res.data);
            // Handle server response
        })
        .catch(error => {
            console.error('Error posting data to the server:', error);
        });
};



  function moveToMint(e){
    setValue(e)
    setTabs(1)
  }

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Update the URL to where your Express app is running
    const fetchData = async () => {
      const result = await fetch('https://getrunes.nukebot.io/scrape');
      const body = await result.json();
      setItems(body);
    };

    fetchData();
  }, []); // Empty array ensures this effect runs only once after the initial render
  const filteredItems = items.filter(item =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const [details, setDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const getDetail = async (e) => {
    setDetails({});
    setLoading(true)
    const result = await fetch(`https://getrunes.nukebot.io/rune/${e}`);
    const body = await result.json();
    setDetails(body);
    setLoading(false)

  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    getDetail(e)
    setOpen(true);
  };

  const handleClose = () => {
    setDetails({})
    setOpen(false);
  };

    // Function to recursively flatten and handle nested objects (like 'mint' in your case)
    const flattenDetails = (obj, parentKey = '') => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          acc = { ...acc, ...flattenDetails(value, newKey) };
        } else {
          acc[newKey] = value.toString();
        }
        return acc;
      }, {});
    };
  
    // Flatten details object to handle nested properties like 'mint'
    const flatDetails = flattenDetails(details);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    // Handle change page
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    // Handle change rows per page
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0); // Reset page to 0 when changing rows per page
    };
  
    // Paginate filteredItems
    const paginatedItems = filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  
    const [tabs, setTabs] = useState(0)

    const [mintRepeat, setMintRepeat] = useState(0)
    const [sats, setSats] = useState(0)



    const [password, setPassword] = useState(null)
    const [email, setEmail] = useState(null)

    async function submit(){
      console.log(process.env.REACT_APP_API_URL)
      const opts = {
          headers:{
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              "Content-Type": "application/json"
          }
      };
      const body = JSON.stringify({password, email})
      try {
         const res =  await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, opts)
         localStorage.setItem("access", res.data.access)
         localStorage.setItem("refresh", res.data.refresh)
         setLogin(true)
         console.log(res.data, "this are the keys")
      } catch (err) {
        handleClick({ vertical: 'top', horizontal: 'center' })
        console.log("wrong password")
      }

  }


  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() =>{
    if(value !== null){
      getDetail(value)
    }
  },[value])

  function extractInteger(inputString) {
    // Using regular expression to find the first sequence of digits
    const match = inputString?.match(/^\d+/);
    // If there's a match, convert it to an integer, otherwise return null
    return match ? parseInt(match[0], 10) : null;
  }

  const [showDetails, setShowDetails] = useState(true)

  const [referalName, setReferalName] = useState(null)

  return (
    <HomeMainContainer>
       <HomeContainer className='hero'>
        <HomeWrapper>
            <LoginWrapper  className='hero'>
            <div className='glassMorph' style={{width:"450px",height:"350px", borderRadius:"10px", color:"black", alignItems:"center", justifyContent:"center", display:"flex" }}>
              <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", padding:"20px", width:"100%", gap:"10px"}} >
               <img src={Logo} alt="nuke logo" style={{width:"120px", objectFit:"contain"}} />
               {/* <h4>You have been invited to NukeBot group by {referalName}</h4> */}
              </div>
            </div>
          </LoginWrapper>
        </HomeWrapper>
       </HomeContainer>
    </HomeMainContainer>
  )
}

export default HomePage
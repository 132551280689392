import React, { useEffect, useState } from 'react'
import {FaBars, FaCopy, FaLink, FaSearchDollar} from 'react-icons/fa'
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink2
} from './NavbarElements'
import Logo from '../assets/images/logo.png'

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CiSettings } from "react-icons/ci";
import axios from 'axios';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const Navbar = ({toggle, setLanguage, lang, login}) => {
    const [walletP2TR, setWalletP2TR] = useState("")
    async function getWallet(){
        console.log(process.env.REACT_APP_API_URL)
        const opts = {
            headers:{
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                "Content-Type": "application/json"
            }
        };
        try {
           const res =  await axios.get(`${process.env.REACT_APP_API_URL}/api/wallet/get/`,  opts)
           setWalletP2TR(res.data[0].pubkeyP2TR)
           console.log(res.data, "this are the keys")
        } catch (err) {
          handleClick({ vertical: 'top', horizontal: 'center' })
          console.log("wrong password")
        }
    }
    const [balance, setBalance] = useState(0)
    async function getWalletBalance(){
        console.log(process.env.REACT_APP_API_URL)
        const opts = {
            headers:{
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                "Content-Type": "application/json"
            }
        };
        try {
           const res =  await axios.get(`${process.env.REACT_APP_API_URL_BALANCE}/wallet-balance/${walletP2TR}`,  opts)
           setBalance(res.data.balance)
           console.log(res.data, "this are the keys")
        } catch (err) {
          handleClick({ vertical: 'top', horizontal: 'center' })
          console.log("wrong password")
        }
    }

    function ShortenString( fullString ) {
        console.log(fullString,"fs",walletP2TR)
        if (fullString?.length <= 16) {
          return fullString;  // Returns the original string if it's short enough
        }
      
        const start = fullString?.slice(0, 6);
        const end = fullString?.slice(-6);
        return `${start}...${end}`;
      }

    useEffect(() =>{
        if(login){
            getWallet()
        }
    },[login])
    useEffect(() =>{
        if(walletP2TR !== ""){
            getWalletBalance()
        }
    },[walletP2TR])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
       <>
           <Nav>
               <NavbarContainer>
                   <NavLogo to="/">
                       <img style={{width:"100px", objectFit:"contained"}}  src={Logo} alt="/"/>
                   </NavLogo>
                   {/* <MobileIcon onClick={toggle}>
                       <FaBars />
                   </MobileIcon> */}
                   <NavMenu>
                       {/* <NavItem>
                           <NavLinks to="/"> Home </NavLinks>
                       </NavItem> */}
                       {/* <NavItem>
                           <NavLinks to="/about-us"> Features </NavLinks>
                       </NavItem>
                       <NavItem>
                           <NavLinks to="/about-us"> Updates </NavLinks>
                       </NavItem>
                       <NavItem>
                           <NavLinks to="/blog"> Docs </NavLinks>
                       </NavItem> */}
                       {/* <NavItem>
                           <NavLinks to="/solutions">  {Language[lang === "tr" ?  0 : 1]?.navbar_details} </NavLinks>
                       </NavItem>
                       <NavItem>
                           <NavLinks to="/contact">  {Language[lang === "tr" ?  0 : 1]?.navbar_contact} </NavLinks>
                       </NavItem> */}
                   </NavMenu>
                   {login ? 
                   <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Account settings">
                        <Button
                            onClick={handleClick}
                            style={{background:"#ff3026"}}
                            variant="contained"
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                             My Wallet 
                        </Button>
                        </Tooltip>
                    </Box>
                    :""}
                   <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        width:"350px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <div style={{width:"100%", display:"flex", alignItems:"end", justifyContent:"center", marginBottom:"15px"}}>
                        <img style={{width:"100px", objectFit:"contained"}}  src={Logo} alt="/"/>
                        <CiSettings  style={{position:"absolute",right:20,fontSize:"20px", cursor:"pointer"}}/>
                    </div>
                    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px"}}>
                        <Typography color="error">Bitcoin Testnet activated</Typography>
                    </div>
                    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px"}}>
                        <Typography variant='h5'>{balance/100000000} BTC</Typography>
                    </div>
                    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px"}}>
                        <Typography style={{color:"grey"}} variant='span'>{ShortenString(walletP2TR)}</Typography>
                        &nbsp;
                        <FaCopy style={{cursor:"pointer",color:"grey"}}/>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <a style={{display:"flex", alignItems:"center", cursor:"pointer", textDecoration:"none"}} target='__blank' href={`https://mempool.space/tr/testnet/address/${walletP2TR}`}>
                            <Typography variant='span'>View History</Typography>
                            &nbsp;
                            <FaLink  style={{cursor:"pointer",color:"grey"}}/>
                        </a>
                    </div>
                    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <Button>Receive</Button>
                        <Button>Send</Button>
                    </div>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Runes" {...a11yProps(0)} />
                        <Tab label="Inscriptions" {...a11yProps(1)} />
                        <Tab label="Activity" {...a11yProps(2)} />
                    </Tabs>
                    <CustomTabPanel value={value} index={0}>
                        <div style={{height:"150px"}}>
                            No Rune Found
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <div style={{height:"150px"}}>
                            No Inscriptions Found
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <div style={{height:"150px"}}>
                           Activity
                        </div>
                    </CustomTabPanel>
                </Menu>
               </NavbarContainer>
           </Nav>  
       </>
    )
}

export default Navbar

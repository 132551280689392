import React, {useState} from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../footer'
import Navbar from '../navbar'
// import SidebarElements from './Sidebar'

const Layout = ({login}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen);
    }
  return (
    <React.Fragment>
        {/* <SidebarElements isOpen={isOpen}  toggle={toggle}/> */}
        <Navbar login={login} toggle={toggle}/>
        <Outlet/>
        <Footer/>
    </React.Fragment>
  )
}

export default Layout;
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Land = ({setLogin,setJWTToken}) => {
  let navigate = useNavigate()
  // Function to get a query parameter by name from the URL
  function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  // Check if the 'auth' parameter is set to 'success'
  if (getQueryParam('auth') === 'success') {
    // Set login status to true
    // You might want to store this in local storage, a cookie, or manage it through your state management system
    localStorage.setItem('isLoggedIn', 'true');
    setJWTToken(getQueryParam('jwt'))
    setLogin(true)

    // Redirect to the home page
    navigate("/"); // Change '/home' to your actual home page URL
  }
  return (
    <div>redirecting...</div>
  )
}

export default Land
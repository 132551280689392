import './App.css';
import {BrowserRouter,Routes,Route  } from 'react-router-dom';
import Layout from './components/layout';
import HomePage from './pages/HomePage';
import { useState } from 'react';
import Land from './pages/Land';
import AcceptReferal from './pages/AcceptReferal';
import CreateRefferal from './pages/Craete';

function App() {
  const  [jwtToken, setJWTToken] = useState(false)
  const  [loggedIn, setLoggedIn] = useState(false)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout login={loggedIn} />}>
          <Route path="/" element={<HomePage login={loggedIn} setLogin={setLoggedIn} jwtToken={jwtToken}/>}/>
          <Route path="/i/:id" element={<AcceptReferal login={loggedIn} setLogin={setLoggedIn} jwtToken={jwtToken}/>}/>
          <Route path="/land" element={<Land login={loggedIn} setLogin={setLoggedIn} setJWTToken={setJWTToken}/>}/>
          <Route path="/create-refferal-link" element={<CreateRefferal login={loggedIn} setLogin={setLoggedIn} setJWTToken={setJWTToken}/>}/>
          {/* <Route path="/index.html" element={<Home language={language}/>}/>
          <Route path="/user-agreement" element={<Useragreement language={language}/>}/>
          <Route path="/gdpr" element={<KVKK language={language}/>}/>
          <Route path="/about-us" element={<AboutUs language={language}/>}/> */}

        </Route>
      </Routes>
  </BrowserRouter>
  );
}

export default App;

import styled from "styled-components"


export const HomeMainContainer = styled.div`
    display:flex;
    flex-direction:column;
`

export const HomeContainer = styled.div`
    width:100%;
`
export const HomeWrapper = styled.div`
    display:flex;
    color:white;
    flex-direction:column;
    height:100%;
    align-items:center;
    justify-content:start;
    margin-top:50px;
    margin-bottom:50px;
`
export const LoginWrapper = styled.div`
    display:flex;
    color:white;
    flex-direction:column;
    height:100%;
    align-items:center;
    justify-content:center;
`

export const ActionButtons = styled.a`
    background: white; 
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;    border: 1px solid white;
    cursor:pointer;
    color:#ff3026;
    padding:10px;
    width:150px;
    text-align:center;
    border-radius:10px;
`

export const FeaturesContainer = styled.div`
    width:100%;
    background:white;
`
export const FeaturesContainerMain = styled.div`
    width:100%;
    margin-top:100px;
`

export const FeaturesWrapper = styled.div`
    color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    color:#000;
`

export const GlassInfoBox = styled.div`
    height:150px;
    width:250px;
    justify-content:flex-start;
    align-items:center;
    flex-direction:column;
    display:flex;
`